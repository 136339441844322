import * as React from 'react';

import SetNewPreference from '../SetNewPreference/SetNewPreference'
import { Button, CardActionArea, CardActions } from '@mui/material';
import ResponsiveAppBar from '../ResponsiveAppBar/ResponsiveAppBar'

const UserProfile = () => {
  
    return (
        <div>
            <SetNewPreference />
        </div>
  );
};
export default UserProfile;